<template>
	<div class="view fixed-footer">
		<div class="main">
			<b-row class="" align-h="center">
				<b-col class="text-center form-wrap">
					<h3>사용에 동의하는 앱에 체크해 주세요.</h3>
					<div v-for="app in apps" :key="app.id" class="app-list">
						<b-form-checkbox v-model="app.agreed" :name="app.name" :id="app.id" :unchecked-value="null" class="text-left">
							{{ app.name }}
						</b-form-checkbox>
					</div>
					<b-button variant="primary" @click="consent" class="btn">앱 사용 동의 저장</b-button>
				</b-col>
			</b-row>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '@/components/Footer';

// IE 하위버전 경고
if (navigator.userAgent.indexOf('MSIE') !== -1) {
	alert('이 웹사이트는 인터넷 익스플로러 10 이하에서 제대로 작동하지 않을 수 있습니다. 최신 브라우저에서 이용해주세요.');
}
export default {
	name: 'ConsentToUseApp',
	metaInfo: {
		// 페이지 제목 설정
		title: '앱 사용 동의',
		titleTemplate: '%s | NOLGONG SCHOOL',
	},
	data() {
		// 로그인 시 필요한 필드들
		return {
			// 로그인 데이터
			apps: [],
		};
	},
	methods: {
		getAppList() {
			// 앱 목록 가져오기
			this.$store.dispatch('NOLAUTH_APPLIST').then((res) => {
				console.log(res);
				if (res.status == 200) {
					this.apps = res.data.apps;
				}
			});
		},
		consent() {
			//console.log(this.apps);
			//console.log(this.$route.params.app_id);

			// 앱 사용 동의
			this.$store
				.dispatch('NOLAUTH_CONSENT', {
					app_list: this.apps,
					redirect_app_id: this.$route.params.app_id,
				})
				.then((res) => {
					console.log(res);
					if (res.status == 200) {
						console.log(res.data.redirect_uri);
						if (res.data.redirect_uri) {
							window.location.href = res.data.redirect_uri + '?app_id=' + res.data.app_id + '&token=' + res.data.token;
						} else {
							this.$router.push('/');
						}
					}
				});
		},
	},
	components: {
		Footer,
	},
	mounted() {
		this.getAppList();
		console.log(this.$route.params);
		console.log(this.$route.query);
	},
};
</script>

<style scoped>

@media (min-width: 992px) {
	.main {
		display: flex;
		align-content: center;
		justify-content: center;
		margin-top: 30px;
		overflow: wrap;
	}

	.app-list {
		width: 400px;
		font-size: 16px;
		margin: 15px;
		background: #f5f5f5;
		padding: 10px;
		border-radius: 5px;
	}
}

@media (max-width: 576px) {
	.main {
		display: flex;
		align-content: center;
		justify-content: center;
		margin-top: 30px;
		overflow: wrap;
	}

	.app-list {
		width: 400px;
		font-size: 16px;
		margin: 15px;
		background: #f5f5f5;
		padding: 10px;
		border-radius: 5px;
	}
}

@media (max-width: 375px) {
	.main {
		display: flex;
		align-content: center;
		justify-content: center;
		margin-top: 30px;
		overflow: wrap;
	}

	.app-list {
		width: 300px;
		font-size: 16px;
		margin: 15px;
		background: #f5f5f5;
		padding: 10px;
		border-radius: 5px;
	}
}

h3 {
		margin-bottom: 20px;
}
.btn {
	margin-top: 20px;
}

</style>
